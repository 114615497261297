<template>
	<!-- eslint-disable -->
	<div>
		<div class="md:flex md:items-center md:justify-between mb-5">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					Clinical Research
				</h2>
			</div>
		</div>

		<div class="w-full">
			<div class="flex flex-col">
				<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div class="shadow border-b border-gray-200 sm:rounded-lg">
							<table class="min-w-full divide-y divide-gray-200">
								<thead class="bg-gray-50">
									<tr>
										<th
											scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Condition
										</th>
										<th
											scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Principal Investigator
										</th>
										<th
											scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Description
										</th>
										<th scope="col" class="relative px-6 py-3">
											<span class="sr-only">Actions</span>
										</th>
									</tr>
								</thead>
								<draggable
									v-model="research"
									handle=".handle"
									tag="tbody"
									item-key="name"
									class="bg-white divide-y divide-gray-200"
									@change="itemMoved"
								>
									<template #item="{ element, index }">
										<tr class="hover:bg-blue-50">
											<td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
												{{ element.condition }}
											</td>
											<td
												class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
												v-html="element.principal_investigator"
											></td>
											<td
												class="px-6 py-4 text-sm font-medium text-gray-900"
												v-html="element.description"
											></td>
											<td
												class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center gap-x-8"
											>
												<button
													type="button"
													class="relative inline-flex items-center p-1 border border-transparent shadow-sm font-medium rounded-md bg-red-100 text-red-600 hover:bg-red-200 focus:outline-none"
													@click="confirmDelete(element)"
												>
													<XIcon class="h-5 w-5" aria-hidden="true" />
												</button>
												<button
													type="button"
													class="relative inline-flex items-center p-1 border border-transparent shadow-sm font-medium rounded-md bg-yellow-200 text-yellow-600 hover:bg-yellow-300 focus:outline-none"
													@click="editResearch(index)"
												>
													<PencilIcon class="h-5 w-5" aria-hidden="true" />
												</button>
												<div class="flex-none flex justify-end items-center">
													<SwitchVerticalIcon
														class="h-5 w-5 text-gray-400 handle"
														aria-hidden="true"
													/>
												</div>
											</td>
										</tr>
									</template>
								</draggable>
							</table>

							<div v-show="research.length === 0" class="flex justify-center mt-5 text-gray-600">
								<span class="text-lg">No Clinical Research</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="mt-12 pb-5 border-b border-gray-200">
				<h3 v-show="!editing" class="text-lg leading-6 font-medium text-gray-900">
					Add Clinical Research
				</h3>
				<h3 v-show="editing" class="text-lg leading-6 font-medium text-gray-900">
					Editing Clinical Research
					<strong>{{ edit.condition }}</strong>
				</h3>
			</div>

			<div class="mt-5 grid grid-cols-6 gap-x-8">
				<FieldsInput
					v-model="edit.condition"
					:field="{
						type: 'input',
						inputType: 'text',
						label: 'Condition',
						model: 'condition',
						required: true,
						colSpan: 'col-span-1',
					}"
				/>

				<FieldsTipTapEditor
					v-model="edit.principal_investigator"
					:field="{
						label: 'Principal Investigator',
						model: 'principal_investigator',
						required: true,
						colSpan: 'col-span-2',
					}"
				/>

				<FieldsTipTapEditor
					v-model="edit.description"
					:field="{
						label: 'Description',
						model: 'description',
						required: true,
						colSpan: 'col-span-3',
					}"
				/>
			</div>

			<div class="mt-5 flex justify-end gap-x-5">
				<button
					v-show="editing"
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none"
					@click="cancelEdit"
				>
					<XIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Cancel
				</button>

				<button
					v-show="editing"
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none"
					@click="update"
				>
					<SaveIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Save Clinical Research
				</button>

				<button
					v-show="!editing"
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none disabled:opacity-50"
					:disabled="!formValid"
					@click="store"
				>
					<PlusCircleIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Add Clinical Research
				</button>
			</div>
		</div>

		<ModalsConfirmation
			ref="confirmation"
			:open="confirmationOpen"
			:confirmation-config="confirmationConfig"
			@closeModal="closeModal"
			@confirm="confirm"
		/>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

import draggable from 'vuedraggable'
import { SwitchVerticalIcon, PencilIcon, XIcon, PlusCircleIcon, SaveIcon } from '@heroicons/vue/solid'

export default {
	name: 'ClinicalResearch',
	components: {
		draggable,
		SwitchVerticalIcon,
		XIcon,
		PencilIcon,
		PlusCircleIcon,
		SaveIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			editing: false,
			edit: {
				condition: null,
				principal_investigator: null,
				description: null,
			},
			confirmationOpen: false,
			confirmationConfig: {},
			research: [],
			// research: [
			// 	{
			// 		id: '276b7242-dacf-4b44-9649-b43f4b8ee3f8',
			// 		condition: 'Cancer',
			// 		principal_investigator: '<p class="mb-2">Preet Singh, MD</p>',
			// 		description: '<p class="mb-2">Members of Heartland NCORP Cancer Research</p>',
			// 	},
			// 	{
			// 		id: '39fa320b-ad53-4d95-9037-0afbe289cbcb',
			// 		condition: 'C-difficile',
			// 		principal_investigator: '<p class="mb-2">Steven O\'Marro, MD</p>',
			// 		description:
			// 			'<p class="mb-2">A Phase 3 study of SER109 (ecbiotic Drug) to reduce recurrence of C-diff infection in adults who have received antibiotic treatment for recurrent C-diff infection.</p>',
			// 	},
			// ],
		}
	},
	computed: {
		formValid: function() {
			return this.edit.condition && this.edit.principal_investigator && this.edit.description
		},
	},
	mounted() {
		this.fetchClinicalResearch()
	},
	methods: {
		fetchClinicalResearch() {
			this.loaderShow()

			this.api.clinicalResearch
				.all()
				.then(data => {
					this.research = data
				})
				.catch(error => {
					this.toast.error('Error fetching clinical research:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		editResearch(index) {
			this.editing = true
			this.edit = JSON.parse(JSON.stringify(this.research[index]))
			console.log('this.edit', this.edit)
		},

		cancelEdit() {
			this.editing = false
			this.edit = {
				condition: null,
				principal_investigator: null,
				description: null,
			}
		},

		store() {
			console.log('creating')
			this.loaderShow()

			this.api.clinicalResearch
				.store(this.edit)
				.then(data => {
					this.cancelEdit()
					this.research = data
					this.toast.success('Successfully create a clinical research')
				})
				.catch(error => {
					this.toast.error('Error creating clinical research:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		update() {
			console.log('updating')
			this.loaderShow()

			this.api.clinicalResearch
				.update(this.edit, this.edit.id)
				.then(data => {
					this.cancelEdit()
					this.research = data
					this.toast.success('Successfully updated a clinical research')
				})
				.catch(error => {
					this.toast.error('Error updating clinical research:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		closeModal() {
			this.confirmationOpen = false
			this.confirmationConfig = {}
			delete this.$refs.confirmation.id
		},

		confirm(callback) {
			this[callback]()
		},

		confirmDelete(item) {
			this.confirmationOpen = true
			this.confirmationConfig = {
				callback: 'deleteResearchItem',
				title: 'Delete Clinical Research Item',
				content: 'Are you sure you want to delete this clinical research item?',
			}
			this.$refs.confirmation.id = item.id
		},

		deleteResearchItem() {
			let id = this.$refs.confirmation.id
			let index = this.research.findIndex(x => x.id === id)
			this.loaderShow()

			this.api.clinicalResearch
				.destroy(id)
				.then(data => {
					this.research = data
					this.toast.success('Successfully deleted a clinical research')

					this.closeModal()
				})
				.catch(error => {
					this.toast.error('Error deleting clinical research:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		itemMoved(evt) {
			let ids = []
			this.research.forEach(element => {
				ids.push(element.id)
			})
			console.log(ids)
			this.loaderShow()

			this.api.clinicalResearch
				.reorder(ids)
				.then(data => {
					this.toast.success('Successfully reordered the clinical research')
					// this.fetchClinicalResearch()
				})
				.catch(error => {
					this.toast.error('Error reordering clinical research:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>
